import React from 'react';

const Reports: React.FC = () => {
  return (
    <div>
      <h2>Relatórios</h2>
      {/* Conteúdo da página de Relatórios */}
    </div>
  );
};

export default Reports;
