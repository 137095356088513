import React from 'react';
import { Link, Outlet } from 'react-router-dom';

const Financial: React.FC = () => {
  return (
    <div>
      <h2>Financeiro</h2>
      <ul>
        <li><Link to="/dashboard/financial/accounts-payable">Contas a Pagar</Link></li>
        <li><Link to="/dashboard/financial/accounts-receivable">Contas a Receber</Link></li>
      </ul>
      <Outlet />
    </div>
  );
};

export default Financial;
