import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import './dashboard.css';

const Dashboard: React.FC = () => {
    const location = useLocation();
    const [cadastrosOpen, setCadastrosOpen] = useState(false);
    const [suprimentosOpen, setSuprimentosOpen] = useState(false);
    const [vendasOpen, setVendasOpen] = useState(false);
    const [financasOpen, setFinancasOpen] = useState(false);
    const [servicosOpen, setServicosOpen] = useState(false);

    const data = [
        { name: 'Jan', vendas: 100 },
        { name: 'Fev', vendas: 200 },
        { name: 'Mar', vendas: 150 },
        { name: 'Abr', vendas: 300 },
        { name: 'Mai', vendas: 250 },
        { name: 'Jun', vendas: 180 },
    ];

    const shouldShowChart = location.pathname === '/dashboard';

    return (
        <div className="dashboard-container">
            <div className="sidebar">
                <ul>
                    <li>
                        <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li>
                        <button onClick={() => setCadastrosOpen(!cadastrosOpen)}>Cadastros</button>
                        {cadastrosOpen && (
                            <ul>
                                <li>
                                    <Link to="/dashboard/users">Clientes e Fornecedores</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/products">Produtos</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/financial/receivables">Serviços</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/financial/receivables">Vendedores</Link>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <button onClick={() => setSuprimentosOpen(!suprimentosOpen)}>Suprimentos</button>
                        {suprimentosOpen && (
                            <ul>
                                <li>
                                    <Link to="/dashboard/users">Controle de estoques</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/financial/receivables">Ordens de Compra</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/financial/receivables">Notas de Entrada</Link>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <button onClick={() => setVendasOpen(!vendasOpen)}>Vendas</button>
                        {vendasOpen && (
                            <ul>
                                <li>
                                    <Link to="/dashboard/users">PDV</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/financial/receivables">Propostas Comerciais</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/financial/receivables">Pedidos de Venda</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/financial/receivables">Comissões</Link>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <button onClick={() => setFinancasOpen(!financasOpen)}>Finanças</button>
                        {financasOpen && (
                            <ul>
                                <li>
                                    <Link to="/dashboard/users">Caixa</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/financial/receivables">Contas a Pagar</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/financial/receivables">Contas a Receber</Link>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <button onClick={() => setServicosOpen(!servicosOpen)}>Serviços</button>
                        {servicosOpen && (
                            <ul>
                                <li>
                                    <Link to="/dashboard/users">Ordens de Serviço</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/financial/receivables">Notas de Serviço</Link>
                                </li>
                            </ul>
                        )}
                    </li>
                </ul>
            </div>
            <div className="content">
                {shouldShowChart && (
                    <div className="chart-container">
                        <h2>Gráfico de Vendas</h2>
                        <BarChart width={600} height={300} data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="vendas" fill="#8884d8" />
                        </BarChart>
                    </div>
                )}
                <Outlet />
            </div>
        </div>
    );
};

export default Dashboard;
