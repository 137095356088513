import React from 'react';

const AccountsPayable: React.FC = () => {
  return (
    <div>
      <h3>Contas a Pagar</h3>
      {/* Conteúdo da página de Contas a Pagar */}
    </div>
  );
};

export default AccountsPayable;
