import React, { useState } from 'react';
import './supplierForm.css';

const SupplierForm: React.FC = () => {
  const [name, setName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [notes, setNotes] = useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Lógica para lidar com o envio do formulário de cadastro de fornecedor
    // Você pode acessar os valores dos campos usando as variáveis de estado (name, cnpj, etc.)
  };

  return (
    <div className="supplier-form">
      <h2>Cadastro de Fornecedor</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Nome:</label>
          <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div>
          <label htmlFor="cnpj">CNPJ/CPF:</label>
          <input type="text" id="cnpj" value={cnpj} onChange={(e) => setCnpj(e.target.value)} />
        </div>
        <div>
          <label htmlFor="address">Endereço:</label>
          <input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} />
        </div>
        <div>
          <label htmlFor="phone">Telefone:</label>
          <input type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </div>
        <div>
          <label htmlFor="email">E-mail:</label>
          <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div>
          <label htmlFor="website">Website:</label>
          <input type="text" id="website" value={website} onChange={(e) => setWebsite(e.target.value)} />
        </div>
        <div>
          <label htmlFor="notes">Observações:</label>
          <textarea id="notes" value={notes} onChange={(e) => setNotes(e.target.value)} />
        </div>
        <button type="submit">Cadastrar</button>
      </form>
    </div>
  );
};

export default SupplierForm;
