import React, { useState } from 'react';
import './userForm.css';

const UserForm: React.FC = () => {
    interface PessoaDeContato {
        nome: string;
        setor: string;
        email: string;
        telefone: string;
        ramal: string;
    }

    const [name, setName] = useState('');
    const [fantasia, setFantasia] = useState('');
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [contribuinte, setContribuinte] = useState('');
    const [inscricaoEstadual, setInscricaoEstadual] = useState('');
    const [tipoContato, setTipoContato] = useState('');
    const [cep, setCep] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [uf, setUf] = useState('');
    const [endereco, setEndereco] = useState('');
    const [bairro, setBairro] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [contato, setContato] = useState('');
    const [telefone, setTelefone] = useState('');
    const [telefoneAdicional, setTelefoneAdicional] = useState('');
    const [celular, setCelular] = useState('');
    const [website, setWebsite] = useState('');
    const [email, setEmail] = useState('');
    const [observacaoContato, setObservacaoContato] = useState('');
    const [pessoasDeContato, setPessoasDeContato] = useState<PessoaDeContato[]>([]);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Lógica para lidar com o envio do formulário de cadastro de usuários
    };

    const handleAddPessoaDeContato = () => {
        const novaPessoaDeContato = {
            nome: '',
            setor: '',
            email: '',
            telefone: '',
            ramal: ''
        };
        setPessoasDeContato([...pessoasDeContato, novaPessoaDeContato]);
    };

    const handleUpdatePessoaDeContato = (index: number, field: string, value: string) => {
        const updatedPessoasDeContato = [...pessoasDeContato];
        const updatedPessoaDeContato = { ...updatedPessoasDeContato[index], [field]: value };
        updatedPessoasDeContato[index] = updatedPessoaDeContato;
        setPessoasDeContato(updatedPessoasDeContato);
    };

    const handleRemovePessoaDeContato = (index: number) => {
        const updatedPessoasDeContato = [...pessoasDeContato];
        updatedPessoasDeContato.splice(index, 1);
        setPessoasDeContato(updatedPessoasDeContato);
    };

    return (
        <div>
            <form className="user-form" onSubmit={handleSubmit}>
                <h2>Clientes e Fornecedores</h2>
                <div>
                    <label htmlFor="name">Nome ou Razão Social do contato:</label>
                    <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="fantasia">Nome Fantasia:</label>
                    <input type="text" id="fantasia" value={fantasia} onChange={(e) => setFantasia(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="cpf">CPF:</label>
                    <input type="text" id="cpf" value={cpf} onChange={(e) => setCpf(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="rg">RG:</label>
                    <input type="text" id="rg" value={rg} onChange={(e) => setRg(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="contribuinte">Contribuinte:</label>
                    <select id="contribuinte" value={contribuinte} onChange={(e) => setContribuinte(e.target.value)}>
                        <option value="">Não informado</option>
                        <option value="contribuinte-icms">Contribuinte ICMS</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="inscricaoEstadual">Inscrição Estadual:</label>
                    <input
                        type="text"
                        id="inscricaoEstadual"
                        value={inscricaoEstadual}
                        onChange={(e) => setInscricaoEstadual(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="tipoContato">Tipo de Contato:</label>
                    <select id="tipoContato" value={tipoContato} onChange={(e) => setTipoContato(e.target.value)}>
                        <option value="">Selecione</option>
                        <option value="funcionario">Funcionário</option>
                        <option value="transportador">Transportador</option>
                        <option value="outro">Outro</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="cep">CEP:</label>
                    <input type="text" id="cep" value={cep} onChange={(e) => setCep(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="municipio">Município:</label>
                    <input type="text" id="municipio" value={municipio} onChange={(e) => setMunicipio(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="uf">UF:</label>
                    <input type="text" id="uf" value={uf} onChange={(e) => setUf(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="endereco">Endereço:</label>
                    <input type="text" id="endereco" value={endereco} onChange={(e) => setEndereco(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="bairro">Bairro:</label>
                    <input type="text" id="bairro" value={bairro} onChange={(e) => setBairro(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="numero">Número:</label>
                    <input type="text" id="numero" value={numero} onChange={(e) => setNumero(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="complemento">Complemento:</label>
                    <input type="text" id="complemento" value={complemento} onChange={(e) => setComplemento(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="contato">Contato:</label>
                    <input type="text" id="contato" value={contato} onChange={(e) => setContato(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="telefone">Telefone:</label>
                    <input type="text" id="telefone" value={telefone} onChange={(e) => setTelefone(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="telefoneAdicional">Telefone Adicional:</label>
                    <input
                        type="text"
                        id="telefoneAdicional"
                        value={telefoneAdicional}
                        onChange={(e) => setTelefoneAdicional(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="celular">Celular:</label>
                    <input type="text" id="celular" value={celular} onChange={(e) => setCelular(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="website">Website:</label>
                    <input type="text" id="website" value={website} onChange={(e) => setWebsite(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="email">E-mail:</label>
                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="observacaoContato">Observação do Contato:</label>
                    <textarea
                        id="observacaoContato"
                        value={observacaoContato}
                        onChange={(e) => setObservacaoContato(e.target.value)}
                    ></textarea>
                </div>
                <div>
                    <h3>Pessoas de Contato</h3>
                    {pessoasDeContato.map((pessoa, index) => (
                        <div key={index}>
                            <div>
                                <label htmlFor={`pessoa-nome-${index}`}>Nome:</label>
                                <input
                                    type="text"
                                    id={`pessoa-nome-${index}`}
                                    value={pessoa.nome}
                                    onChange={(e) => handleUpdatePessoaDeContato(index, 'nome', e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor={`pessoa-setor-${index}`}>Setor:</label>
                                <input
                                    type="text"
                                    id={`pessoa-setor-${index}`}
                                    value={pessoa.setor}
                                    onChange={(e) => handleUpdatePessoaDeContato(index, 'setor', e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor={`pessoa-email-${index}`}>E-mail:</label>
                                <input
                                    type="email"
                                    id={`pessoa-email-${index}`}
                                    value={pessoa.email}
                                    onChange={(e) => handleUpdatePessoaDeContato(index, 'email', e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor={`pessoa-telefone-${index}`}>Telefone:</label>
                                <input
                                    type="text"
                                    id={`pessoa-telefone-${index}`}
                                    value={pessoa.telefone}
                                    onChange={(e) => handleUpdatePessoaDeContato(index, 'telefone', e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor={`pessoa-ramal-${index}`}>Ramal:</label>
                                <input
                                    type="text"
                                    id={`pessoa-ramal-${index}`}
                                    value={pessoa.ramal}
                                    onChange={(e) => handleUpdatePessoaDeContato(index, 'ramal', e.target.value)}
                                />
                            </div>
                            <button type="button" onClick={() => handleRemovePessoaDeContato(index)}>
                                Excluir
                            </button>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddPessoaDeContato}>
                        Adicionar Pessoa de Contato
                    </button>
                </div>
                <button type="submit">Cadastrar</button>
            </form>
        </div>
    );
};

export default UserForm;
