import React from 'react';

const AccountsReceivable: React.FC = () => {
  return (
    <div>
      <h3>Contas a Receber</h3>
      {/* Conteúdo da página de Contas a Receber */}
    </div>
  );
};

export default AccountsReceivable;
