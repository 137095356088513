import React, { useState } from 'react';
import './productForm.css';

const ProductForm: React.FC = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [photos, setPhotos] = useState<File[]>([]);
    const [costPrice, setCostPrice] = useState('');
    const [originalPrice, setOriginalPrice] = useState('');
    const [promotionalPrice, setPromotionalPrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const [sku, setSku] = useState('');
    const [gtin, setGtin] = useState('');
    const [productType, setProductType] = useState('');
    const [category, setCategory] = useState('');
    const [variations, setVariations] = useState('');
    const [origin, setOrigin] = useState('');
    const [ncm, setNcm] = useState('');
    const [sellingPrice, setSellingPrice] = useState('');
    const [unitOfMeasure, setUnitOfMeasure] = useState('');
    const [netWeight, setNetWeight] = useState('');
    const [grossWeight, setGrossWeight] = useState('');
    const [packageType, setPackageType] = useState('');
    const [customPackage, setCustomPackage] = useState('');
    const [packageWidth, setPackageWidth] = useState('');
    const [packageHeight, setPackageHeight] = useState('');
    const [packageLength, setPackageLength] = useState('');
    const [manageStock, setManageStock] = useState('');
    const [initialStock, setInitialStock] = useState('');
    const [minStock, setMinStock] = useState('');
    const [maxStock, setMaxStock] = useState('');

    const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedPhotos = Array.from(event.target.files);
            setPhotos(selectedPhotos);
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Lógica para lidar com o envio do formulário de cadastro de produtos
        // Você pode acessar os valores dos campos usando as variáveis de estado (name, description, etc.)
    };

    return (
        <div className="product-form">
            <button onClick={() => console.log('Clique no botão "Novo Produto"')}>Novo Produto</button>
            <h2>Cadastro de Produtos</h2>
            <form onSubmit={handleSubmit}>
            <div className="form-row"> 
            <div className="form-column">
                <div>
                    <label htmlFor="name">Nome:</label>
                    <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
            </div>
            <div className="form-column">
                <div>
                    <label htmlFor="description">Descrição:</label>
                    <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
            </div>
            </div>
                <div>
                    <label htmlFor="photos">Fotos:</label>
                    <input type="file" id="photos" accept="image/*" multiple onChange={handlePhotoChange} />
                </div>
                <div>
                    <label htmlFor="costPrice">Preço de Custo:</label>
                    <input type="text" id="costPrice" value={costPrice} onChange={(e) => setCostPrice(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="originalPrice">Preço Original:</label>
                    <input type="text" id="originalPrice" value={originalPrice} onChange={(e) => setOriginalPrice(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="promotionalPrice">Preço Promocional:</label>
                    <input type="text" id="promotionalPrice" value={promotionalPrice} onChange={(e) => setPromotionalPrice(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="quantity">Quantidade:</label>
                    <input type="text" id="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="sku">SKU:</label>
                    <input type="text" id="sku" value={sku} onChange={(e) => setSku(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="gtin">GTIN/EAN/ISBN:</label>
                    <input type="text" id="gtin" value={gtin} onChange={(e) => setGtin(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="productType">Tipo de Produto:</label>
                    <select id="productType" value={productType} onChange={(e) => setProductType(e.target.value)}>
                        <option value="">Selecione</option>
                        <option value="Físico">Físico</option>
                        <option value="Digital">Digital</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="category">Categoria:</label>
                    <input type="text" id="category" value={category} onChange={(e) => setCategory(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="variations">Variações:</label>
                    <input type="text" id="variations" value={variations} onChange={(e) => setVariations(e.target.value)} />
                </div>
                <div>
                    <h3>Dimensões e Peso</h3>
                    <div>
                        <label htmlFor="netWeight">Peso Líquido:</label>
                        <input type="text" id="netWeight" value={netWeight} onChange={(e) => setNetWeight(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="grossWeight">Peso Bruto:</label>
                        <input type="text" id="grossWeight" value={grossWeight} onChange={(e) => setGrossWeight(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="packageType">Tipo de Embalagem:</label>
                        <select id="packageType" value={packageType} onChange={(e) => setPackageType(e.target.value)}>
                            <option value="">Selecione</option>
                            <option value="Pacote/caixa">Pacote/caixa</option>
                            <option value="Envelope">Envelope</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="customPackage">Embalagem Customizada:</label>
                        <select id="customPackage" value={customPackage} onChange={(e) => setCustomPackage(e.target.value)}>
                            <option value="">Selecione</option>
                            <option value="Embalagem customizada">Embalagem customizada</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="packageWidth">Largura:</label>
                        <input type="text" id="packageWidth" value={packageWidth} onChange={(e) => setPackageWidth(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="packageHeight">Altura:</label>
                        <input type="text" id="packageHeight" value={packageHeight} onChange={(e) => setPackageHeight(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="packageLength">Comprimento:</label>
                        <input type="text" id="packageLength" value={packageLength} onChange={(e) => setPackageLength(e.target.value)} />
                    </div>
                </div>

                <div>
                    <h3>Estoque</h3>
                    <div>
                        <label htmlFor="manageStock">Controlar Estoque:</label>
                        <select id="manageStock" value={manageStock} onChange={(e) => setManageStock(e.target.value)}>
                            <option value="">Selecione</option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="initialStock">Estoque Inicial:</label>
                        <input type="text" id="initialStock" value={initialStock} onChange={(e) => setInitialStock(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="minStock">Estoque Mínimo:</label>
                        <input type="text" id="minStock" value={minStock} onChange={(e) => setMinStock(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="maxStock">Estoque Máximo:</label>
                        <input type="text" id="maxStock" value={maxStock} onChange={(e) => setMaxStock(e.target.value)} />
                    </div>
                </div>

                <div>
                    <h3>Outras informações</h3>
                    <div>
                        <label htmlFor="origin">Origem:</label>
                        <select id="origin" value={origin} onChange={(e) => setOrigin(e.target.value)}>
                            <option value="">Selecione</option>
                            <option value="0">Nacional</option>
                            <option value="1">Importado</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="ncm">NCM:</label>
                        <input type="text" id="ncm" value={ncm} onChange={(e) => setNcm(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="sellingPrice">Preço de Venda:</label>
                        <input type="text" id="sellingPrice" value={sellingPrice} onChange={(e) => setSellingPrice(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="unitOfMeasure">Unidade de Medida:</label>
                        <input type="text" id="unitOfMeasure" value={unitOfMeasure} onChange={(e) => setUnitOfMeasure(e.target.value)} />
                    </div>
                </div>

                <button type="submit">Salvar</button>
            </form>
        </div>
    );
};

export default ProductForm;
