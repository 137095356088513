import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import UserForm from './components/userForm/UserForm';
import ProductForm from './components/productForm/ProductForm';
import SupplierForm from './components/supplierForm/SupplierForm';
import Financial from './components/Financial';
import AccountsPayable from './components/AccountsPayable';
import AccountsReceivable from './components/AccountsReceivable';
import Reports from './components/Reports';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard/*" element={<Dashboard />}>
          {/* <Route index element={<h1>Conteúdo da Dashboard</h1>} /> */}
          <Route path="users" element={<UserForm />} />
          <Route path="products" element={<ProductForm />} />
          <Route path="suppliers" element={<SupplierForm />} />
          <Route path="financial/*" element={<Financial />}>
            <Route path="accounts-payable" element={<AccountsPayable />} />
            <Route path="accounts-receivable" element={<AccountsReceivable />} />
          </Route>
          <Route path="reports" element={<Reports />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
